<template>
  <div :class="['Nav', { transparent: isTraCopy }]">
    <nav class="nav_box">
      <router-link to="/index" class="navLeft">
        <img
          class="nav_logo"
          src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/logo.png"
          alt="惠合科技"
          @click="sensors('惠合logo', '')"
        />
      </router-link>
      <div class="navRight">
        <div class="nav_btn" @click="sensors">
          <router-link
            :class="{
              ignore_nav_btn_top: true,
              hover: hover === '首页',
            }"
            to="/index"
            ><div @click="sensors('首页', '')">首页</div></router-link
          >
        </div>
        <div class="nav_btn">
          <a :class="{ ignore_nav_btn_top: true, hover: marketPlatformHover }"
            >营销平台</a
          >
          <div class="nav_alert">
            <div class="nav_item_all">
              <router-link
                v-for="item in marketPlatform"
                :key="item.href"
                :to="item.href"
                class="ignore_nav_alert_item"
                :class="{ hover: item.name === hover }"
                ><div @click="sensors('营销平台', item.name)">
                  {{ item.name }}
                </div></router-link
              >
            </div>
          </div>
        </div>
        <div class="nav_btn">
          <a :class="{ ignore_nav_btn_top: true, hover: planHover }"
            >营销方案</a
          >
          <div class="nav_alert">
            <div class="nav_item_all">
              <router-link
                v-for="item in plans"
                :key="item.href"
                :to="item.href"
                class="ignore_nav_alert_item"
                :class="{ hover: item.name === hover }"
                ><div @click="sensors('营销方案', item.name)">
                  {{ item.name }}
                </div></router-link
              >
            </div>
          </div>
        </div>
        <div class="nav_btn">
          <router-link
            :class="{
              ignore_nav_btn_top: true,
              hover: hover === '客户案例',
            }"
            to="/case"
            ><div @click="sensors('客户案例', '')">客户案例</div></router-link
          >
        </div>
        <div class="nav_btn">
          <a :class="{ ignore_nav_btn_top: true, hover: aboutHover }"
            >关于我们</a
          >
          <div class="nav_alert">
            <div class="nav_item_all">
              <div v-for="item in about" :key="item.href">
                <div v-if="item.name != '加入我们'">
                  <router-link
                    :to="item.href"
                    class="ignore_nav_alert_item"
                    :class="{ hover: item.name === hover }"
                    ><div @click="sensors('关于我们', item.name)">
                      {{ item.name }}
                    </div></router-link
                  >
                </div>
                <div v-if="item.name == '加入我们'">
                  <a
                    class="ignore_nav_alert_item"
                    :href="item.href"
                    :class="{ hover: item.name === hover }"
                    ><div @click="sensors('关于我们', item.name)">
                      {{ item.name }}
                    </div></a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ignore_nav_online" @click="sensors('立即咨询', '')">
          <router-link
            class="ignore_"
            :to="`/contact?from=${pathName[getPath2name()]}&moudle=顶部导航栏`"
            >立即咨询</router-link
          >
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { marketPlatform, pathName, about, plans } from "@/utils/enum.js"
import { getPath2name } from "@/utils/utils.js"
export default {
  name: "Nav",
  props: {
    isTranstparent: {
      type: Boolean,
      default: false,
    },
    // hover: {
    //     type: String,
    //     default: ''
    // }
  },
  data() {
    return {
      isTraCopy: this.isTranstparent,
      marketPlatform,
      about,
      plans,
      hover: pathName[getPath2name()],
      pathName,
    }
  },
  mounted() {
    if (this.isTranstparent) {
      window.addEventListener("scroll", this.getScroll, true)
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.getScroll)
  },
  computed: {
    marketPlatformHover() {
      return this.marketPlatform.some((item) => item.name === this.hover)
    },
    planHover() {
      return this.plans.some((item) => item.name === this.hover)
    },
    aboutHover() {
      return this.about.some((item) => item.name === this.hover)
    },
  },
  methods: {
    getPath2name,
    getScroll() {
      let scrollTop = document.documentElement.scrollTop // 滚动条偏移量
      if (scrollTop > document.documentElement.clientHeight) {
        this.isTraCopy = false
      } else {
        this.isTraCopy = true
      }
    },
    sensors(navName, menuMame) {
      window.sensors.track("nav_menu_click", {
        current_page: pathName[getPath2name()],
        belong_area: "顶部",
        belong_first_navename: navName,
        nav_menu_name: menuMame,
      })
    },
    // handerUrl(item) {
    //     if (item.name === '') {
    //         console.log(1)
    //     }
    // }
  },
}
</script>

<style lang="less" scoped>
@import url("~@/assets/global.less");
.Nav {
  width: 100%;
  height: 88px;
  background: #000000;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;

  .nav_box {
    margin: 0 auto;
    width: calc(100% - 315px);
    height: 88px;
    font-size: 18px;
    color: #fff;
    .navLeft {
      float: left;
      height: 100%;
      line-height: 88px;
    }
    .nav_logo {
      vertical-align: middle;
      width: 160px;
      height: 46px;
      display: inline-block;
    }
    .navRight {
      float: right;
      height: 100%;
      .nav_btn {
        padding: 0 31px;
        float: left;
        height: 100%;
        position: relative;
        a {
          color: #fff;
          line-height: 88px;
        }
        .ignore_nav_btn_top {
          font-size: 16px;
        }
        &:hover {
          .ignore_nav_btn_top {
            color: @primary-color;
          }
          .nav_alert {
            display: block;
          }
        }
        .nav_alert {
          display: none;
          width: 150px;
          background: #fff;
          position: absolute;
          top: 80%;
          left: 50%;
          transform: translateX(-50%);
          border-top: 4px solid @primary-color;

          .nav_item_all {
            overflow: hidden;
            max-height: 500px;
            animation: navHeiht 1s;
          }
          &::before {
            content: "";
            width: 0;
            height: 0;
            border: 12px solid @primary-color;
            border-top-color: transparent;
            border-left-color: transparent;
            border-right-color: transparent;
            position: absolute;
            top: -27px;
            left: 50%;
            transform: translateX(-50%);
          }
          .ignore_nav_alert_item {
            line-height: 36px;
            border-bottom: 1px solid #fff6ed;
            color: #242424;
            text-align: center;
            &:hover {
              color: @primary-color;
            }
          }
        }
      }
      .ignore_nav_online {
        margin-left: 31px;
        text-align: center;
        float: left;
        height: 100%;
        display: flex;
        align-items: center;
        a {
          line-height: 30px;
          font-size: 16px;
          color: #fff;
          background: @primary-color;
          width: 100px;
          height: 30px;
          border-radius: 4px;
          display: inline-block;
        }
      }
    }
  }
}

.transparent {
  background: transparent;
}

.hover {
  color: @primary-color!important;
}

@keyframes navHeiht {
  from {
    // display: none;
    // opacity: 0;
    max-height: 0;
  }
  to {
    // display: block;
    // opacity: 1;
    max-height: 500px;
  }
}
</style>
