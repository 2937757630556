<template>
  <div id="app">
    <Nav :is-transparent="false" />
    <!-- banner -->
    <Banner
      source-url="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/yylx/banner.png"
      title="壹元乐享"
      :sub-title="subTitle"
      alt-text="壹元乐享"
    />
    <!-- 适应品牌需求 -->
    <PPXQ :list="list" />
    <!-- 解决方案 -->
    <div class="jjfa">
      <div class="title">解决方案</div>
      <div class="flex jjfa-content">
        <div class="jjfa-content_item">
          <img
            class="jjfa-content_item-img"
            src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/yylx/5.png"
            alt
          />
          <span class="jjfa-content_item-text">定向签约核销网点</span>
        </div>
        <div class="jjfa-content_item m0">
          <img
            class="jjfa-content_item-img"
            src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/kxsm/step2.png"
            alt
          />
          <span class="jjfa-content_item-text">灵活配置核销流程</span>
        </div>
      </div>
      <div class="flex jjfa-content">
        <div class="jjfa-content_item">
          <img
            class="jjfa-content_item-img"
            src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/yylx/7.png"
            alt
          />
          <span class="jjfa-content_item-text">及时发放核销奖励</span>
        </div>
        <div class="jjfa-content_item">
          <img
            class="jjfa-content_item-img"
            src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/yylx/8.png"
            alt
          />
          <span class="jjfa-content_item-text">精准沉淀终端信息</span>
        </div>
        <div class="jjfa-content_item m0">
          <img
            class="jjfa-content_item-img"
            src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/yylx/9.png"
            alt
          />
          <span class="jjfa-content_item-text">在线追踪活动数据</span>
        </div>
      </div>
    </div>
    <!-- 方案优势 -->
    <div class="fays">
      <div class="title">方案优势</div>
      <div class="flex fays-item">
        <div class="flex item-left">
          <img src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/yylx/10.png" alt />
        </div>
        <div class="item-right">
          <span class="bold">快速覆盖核销网点</span>
          <span
            >通过e店佳平台定向投放活动广告或业代线下签约，可以在短时间内实现核销网点的高覆盖</span
          >
        </div>
      </div>
      <div class="flex fays-item">
        <div class="flex item-left">
          <img src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/yylx/11.png" alt />
        </div>
        <div class="item-right">
          <span class="bold">简化核销流程</span>
          <span
            >业代或经销商通过扫描瓶盖内二维码就可以快速完成商品核销，流程简单、数据可追踪</span
          >
        </div>
      </div>
      <div class="flex fays-item">
        <div class="flex item-left">
          <img src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/yylx/12.png" alt />
        </div>
        <div class="item-right">
          <span class="bold">缩短结算周期</span>
          <span
            >公司在线审核完瓶盖券即可实时发放门店奖励或货补，
            极大缩短了结算周期，提高门店参与度</span
          >
        </div>
      </div>
      <div class="flex fays-item">
        <div class="flex item-left">
          <img src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/yylx/13.png" alt />
        </div>
        <div class="item-right">
          <span class="bold">沉淀私域流量</span>
          <span
            >消费者领券环节可以将流量沉淀到品牌商自己公众号或小程序内，进行二次营销</span
          >
        </div>
      </div>
    </div>
    <!-- 客户案例 -->
    <customer-case
      :menu-id="menuId"
      :title="caseTitle"
      :description="caseDesc"
      news-id="53"
      pic-url="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/khal/yylx.png"
    ></customer-case>
    <!-- 方案保障 -->
    <FABZ />
    <!-- 更多方案 -->
    <more-case></more-case>
    <Bottom />
  </div>
</template>
<script>
import Nav from "@/components/Nav.vue"
import Banner from "@/components/Banner.vue"
import PPXQ from "@/components/PPXQ.vue"
import CustomerCase from "@/components/CustomerCase.vue"
import FABZ from "@/components/FABZ.vue"
import MoreCase from "@/components/MoreCase.vue"
import Bottom from "@/components/Bottom.vue"
import { title, description, keywords } from "./global"
export default {
  metaInfo: {
    title: title,
    meta: [
      {
        name: "keywords",
        content: keywords,
      },
      {
        name: "description",
        content: description,
      },
    ],
  },
  name: "SQYX",
  components: {
    Nav,
    Banner,
    PPXQ,
    CustomerCase,
    FABZ,
    MoreCase,
    Bottom,
  },
  data() {
    return {
      subTitle:
        "借助让利式营销，提升商品复购率，实现从网点签约、在线结算到\n数据沉淀的灵活落地。",
      caseTitle: "惠合科技X本土新锐碳酸饮料",
      caseDesc:
        "品牌2020年开始与惠合科技合作“壹元乐享”项目，通过趣味性的互动环节提升了C端动销和客户粘性，并基于小程序引流构建了品牌私域流量池。",
      menuId: "6",
      list: [
        {
          icon: "https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/yylx/1.png",
          desc: "沉淀私域流量\n打造营销闭环",
        },
        {
          icon: "https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/yylx/2.png",
          desc: "渠道精准营销\n加速新品推广",
        },
        {
          icon: "https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/yylx/3.png",
          desc: "增加品牌曝光\n提升整体销量",
        },
        {
          icon: "https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/yylx/4.png",
          desc: "旺季产品促销\n占领市场份额",
        },
      ],
    }
  },
}
</script>
<style lang="less" scoped>
@import url("~@/assets/global.less");
#app {
  padding-top: 88px;
  .title {
    text-align: center;
    font-size: 46px;
    color: #141414;
  }
  .jjfa {
    padding: 50px 0 15px;
    background: #f7f9fa;
    .title {
      margin-bottom: 60px;
    }
    .jjfa-content {
      margin-bottom: 50px;
      &_item {
        text-align: center;
        margin-right: 50px;
        &-img {
          display: block;
          width: 410px;
          height: 260px;
          margin-bottom: 20px;
          background: #ffffff;
          img {
            display: block;
            width: 100%;
          }
        }
        &-text {
          position: relative;
          padding-left: 20px;
          font-size: 22px;
          font-weight: 400;
          color: #333333;
          box-sizing: border-box;
          &:before {
            content: "";
            position: absolute;
            width: 8px;
            height: 8px;
            top: 14px;
            left: 0;
            background: #333;
            border-radius: 50%;
          }
        }
      }
    }
  }
  .fays {
    padding: 50px 0 55px;
    .title {
      margin-bottom: 50px;
    }
    .fays-item {
      margin-bottom: 26px;
      .item-left {
        width: 80px;
        height: 80px;
        background: #ffe8c7;
        align-items: center;
        img {
          display: block;
          max-width: 100%;
        }
      }
      .item-right {
        position: relative;
        width: 1223px;
        height: 80px;
        padding-left: 30px;
        line-height: 80px;
        font-size: 22px;
        color: #333;
        background: #fffbf7;
        letter-spacing: 1px;
        box-sizing: border-box;
        &:before {
          content: "";
          position: absolute;
          width: 8px;
          height: 8px;
          margin-top: -4px;
          top: 50%;
          left: 15px;
          background: #333;
          border-radius: 50%;
        }
        .bold {
          margin-right: 20px;
          font-size: 26px;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
