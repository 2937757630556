<template>
  <div class="ptbz bg-theme-dark">
    <div class="ptbz-title">平台保障</div>
    <div class="flex">
      <div class="ptbz-item">
        <img
          class="ptbz-item_icon"
          src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/jslx.png"
          alt="惠合"
        />
        <div class="ptbz-item_title">技术领先</div>
        <div class="ptbz-item_text">
          AI视觉识别技术，实现毫秒级响应，保障98%以上的图像识别准确率；大数据分析技术满足实时数据分析和自动化报表应用需求。
        </div>
      </div>
      <div class="ptbz-item">
        <img
          class="ptbz-item_icon"
          src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/xtwd.png"
          alt="惠合"
        />
        <div class="ptbz-item_title">系统稳定</div>
        <div class="ptbz-item_text">
          每秒可支持十万级以上的并发业务执行；全天候业务监控系统，实现自动宕机迁移屏蔽、快照备份，服务可用性达99.99%。
        </div>
      </div>
      <div class="ptbz-item m0">
        <img
          class="ptbz-item_icon"
          src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/aqkk.png"
          alt="惠合"
        />
        <div class="ptbz-item_title">安全可靠</div>
        <div class="ptbz-item_text">
          “云端存储+异地容灾+加密传输”保障数据安全；“用户行为监控+二维码流转机制”保障扫码安全；覆盖活动全流程的“黑白名单+业务审核+资金风控”保障品牌资金结算安全。
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PTBZ",
}
</script>
<style lang="less" scoped>
@import url("~@/assets/global.less");
.ptbz {
  padding: 3vw 0;
  .ptbz-title {
    margin-bottom: 3vw;
    text-align: center;
    font-size: 2vw;
    color: #141414;
  }
  .ptbz-item {
    width: 18vw;
    height: 24vw;
    padding: 1vw 2vw;
    margin-right: 4vw;
    background: url("https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/p-bg.png") center
      no-repeat;
    letter-spacing: 1px;
    box-sizing: border-box;
    .ptbz-item_icon {
      display: block;
      width: 4vw;
      height: 4vw;
      margin: 0 auto 16px;
    }
    .ptbz-item_title {
      position: relative;
      margin-bottom: 2vw;
      text-align: center;
      font-size: 30px;
      color: #141414;
      &:after {
        content: "";
        position: absolute;
        width: 30px;
        height: 4px;
        margin-left: -15px;
        bottom: -1vw;
        left: 50%;
        background: #333;
        border-radius: 2px;
      }
    }
    .ptbz-item_text {
      font-size: 22px;
    }
  }
}
</style>
