<template>
  <div class="fabz">
    <div class="fabz-title">方案保障</div>
    <div class="flex">
      <div class="fabz-item">
        <img
          class="fabz-item_icon"
          src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/other/1.png"
          alt="惠合"
        />
        <div class="fabz-item_title">专人对接</div>
        <div class="fabz-item_text">
          坚持“专人专项”原则，惠合专项运营小组提供覆盖活动策划、推广、执行、落地到追踪复盘的全流程服务。
        </div>
      </div>
      <div class="fabz-item">
        <img
          class="fabz-item_icon"
          src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/other/2.png"
          alt="惠合"
        />
        <div class="fabz-item_title">门店运营</div>
        <div class="fabz-item_text">
          基于惠合150W家线上门店资源，为品牌提供活动门店筛选、推广、签约、核销到结算的一站式服务。
        </div>
      </div>
      <div class="fabz-item">
        <img
          class="fabz-item_icon"
          src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/other/3.png"
          alt="惠合"
        />
        <div class="fabz-item_title">培训教学</div>
        <div class="fabz-item_text">
          支持现场教学、远程培训、视频、图像等多种培训方式，帮助品牌方项目成员快速掌握项目运营操作。
        </div>
      </div>
      <div class="fabz-item">
        <img
          class="fabz-item_icon"
          src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/other/4.png"
          alt="惠合"
        />
        <div class="fabz-item_title">数据应用</div>
        <div class="fabz-item_text">
          基于惠合VMS品牌营销管理平台，品牌商可在线完成活动配置、自动生成活动报表、实时追踪活动进展。
        </div>
      </div>
      <div class="fabz-item m0">
        <img
          class="fabz-item_icon"
          src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/other/5.png"
          alt="惠合"
        />
        <div class="fabz-item_title">7*24H客户服务</div>
        <div class="fabz-item_text">
          活动上线后，面向品牌方、门店、消费者提供7*24小时的人工客服热线。
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "fabz",
}
</script>
<style lang="less" scoped>
@import url("~@/assets/global.less");
.fabz {
  padding: 56px 0 78px;
  .fabz-title {
    margin-bottom: 50px;
    text-align: center;
    font-size: 46px;
    color: #141414;
  }
  .fabz-item {
    width: 290px;
    height: 460px;
    padding: 28px 0;
    margin-right: 20px;
    background: url("https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/other/bg.png") center
      no-repeat;
    background-size: 100% 100%;
    // letter-spacing: 1px;
    box-sizing: border-box;
    .fabz-item_icon {
      display: block;
      width: 76px;
      height: 76px;
      margin: 0 auto 16px;
    }
    .fabz-item_title {
      position: relative;
      margin-bottom: 58px;
      text-align: center;
      font-size: 30px;
      color: #141414;
      &:after {
        content: "";
        position: absolute;
        width: 30px;
        height: 4px;
        margin-left: -15px;
        bottom: -1vw;
        left: 50%;
        background: #333;
        border-radius: 2px;
      }
    }
    .fabz-item_text {
      padding: 0 30px;
      font-size: 22px;
      line-break: anywhere;
    }
  }
}
</style>
