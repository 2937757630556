<template>
  <div id="app">
    <Nav></Nav>
    <a class="Banner">
      <img
        class="banner_img"
        src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/index/caseBanner.png"
        alt="惠合科技"
      />
      <div class="banner_text">
        <div class="title">1000+品牌营销活动落地</div>
        <div class="subtitle">
          助力100+全球知名消费品牌落地超1000场传统渠道数字营销活动
        </div>
        <router-link to="/contact?from=客户案例&moudle=营销方案" class="img-btn"
          >立即咨询</router-link
        >
      </div>

      <!-- <swiper class="swiper" ref="mySwiper" :options="swiperOptions">
                <swiper-slide v-for="(item, index) in bannerList" :key="index">
                    <img class="banner_img" :src="item" alt="惠合科技" />
                </swiper-slide>
            </swiper>-->
    </a>
    <div class="content">
      <div class="content_list">
        <div class="content_item hover" v-for="item in pageData" :key="item.id">
          <router-link :to="{ name: 'newsDetail', query: { id: item.id } }">
            <img class="content_item_img" :src="item.picUrl" alt />
            <div class="content_item_title">{{ item.title }}</div>
            <div class="content_item_tagList">
              <div
                :title="t.labelName"
                class="content_item_tag"
                v-for="t in item.labels"
                :key="t.id"
              >
                {{ t.labelName }}
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <page :allPages="allPages" @on-change="pageChange" />
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import Nav from "@/components/Nav.vue"
import Bottom from "@/components/Bottom.vue"
import Page from "@/components/Page.vue"
// import { swiper, swiperSlide } from 'vue-awesome-swiper'
// import 'swiper/css/swiper.css'
import "swiper/dist/css/swiper.css"
import { doPost } from "@/utils/request"
import { title, description, keywords } from "./global"
export default {
  metaInfo: {
    title: title,
    meta: [
      {
        name: "keywords",
        content: keywords,
      },
      {
        name: "description",
        content: description,
      },
    ],
  },
  name: "Case",
  components: {
    Nav,
    Bottom,
    Page,
    // swiper,
    // swiperSlide
  },
  data() {
    return {
      bannerList: ["https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/index/caseBanner.jpg"],
      swiperOptions: {
        autoplay: true,
        speed: 1000,
        //   loop: true
        // Some Swiper option/callback...
      },
      pageData: [],
      allPages: 0,
    }
  },
  created() {
    this.List()
  },
  methods: {
    List(currentPage = 1) {
      doPost("pms/officialWebsiteArticle/doQueryWithPageByNoLogin.json", {
        currentPage,
        pageSize: 9,
        category: "客户案例",
      }).then((res) => {
        this.pageData = res.datalist
        this.allPages = res.pageCount
      })
    },

    pageChange(page) {
      this.List(page)
    },
  },
}
</script>

<style lang="less" scoped>
@import url("~@/assets/global.less");
body,
html {
  background: #f5f5f5;
}

div {
  transition: all 0.3s;
}
.Banner {
  min-width: 1400px;
  width: 100%;
  padding-top: 88px;
  position: relative;
  .swiper {
    width: 100%;
  }
  .banner_img {
    min-width: 1400px;
    width: 100%;
    height: 650px;
    display: block;
    object-fit: cover;
    cursor: pointer;
  }
  .banner_text {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding-top: 258px;
    text-align: center;
    color: #fff;
    box-sizing: border-box;
    .title {
      margin-bottom: 43px;
      font-size: 58px;
    }
    .subtitle {
      margin-bottom: 97px;
      font-size: 22px;
    }
  }
  .img-btn {
    width: 206px;
    height: 50px;
    margin: 0 auto;
    line-height: 50px;
    text-align: center;
    font-size: 28px;
    color: #fefefe;
    background: #ff7f00;
    letter-spacing: 1px;
    border-radius: 8px;
  }
}
.content {
  width: 1290px;
  margin: 0 auto;
  padding: 65px 0 72px;
  .content_top {
    width: 100%;
    height: 370px;
    background: #fff;
    border-radius: 4px;
    overflow: hidden;
    .boxShow();
    .content_top_img {
      width: 646px;
      height: 100%;
      float: left;
    }
    .content_top_content {
      overflow: hidden;
      padding-left: 20px;
      padding-right: 30px;
    }
    .content_top_title {
      font-size: 22px;
      line-height: 70px;
      .textOverFlow(1);
    }
    .content_top_info {
      font-size: 16px;
      color: #333;
      line-height: 28px;
      .textOverFlow(5);
    }
    .content_btn_list {
      display: flex;
      justify-content: space-between;
      margin-top: 124px;
      font-size: #333;
      font-size: 16px;
      .more {
        color: @primary-color;
        text-decoration: underline;
      }
    }
  }

  .content_list {
    width: 100%;
    &::after {
      content: "";
      clear: both;
      display: block;
    }
  }
  .content_item {
    .boxShow();
    overflow: hidden;
    float: left;
    width: 410px;
    height: 366px;
    margin-top: 30px;
    margin-right: 30px;
    font-size: 18px;
    color: #333;
    &:nth-child(3n) {
      margin-right: 0;
    }
    .content_item_img {
      width: 100%;
      height: 234px;
      object-fit: cover;
      display: block;
    }
    .content_item_title {
      margin: 12px;
      line-height: 28px;
      height: 56px;
      font-size: 22px;
      .textOverFlow(2);
    }
    .content_item_tagList {
      padding: 0 12px;
      overflow: hidden;
      .content_item_tag {
        border-radius: 2px;
        background: @tag-bg-color;
        padding: 0 10px;
        color: @primary-color;
        margin-right: 10px;
        float: left;
        font-size: 18px;
      }
    }
  }
}

.hover {
  cursor: pointer;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 3px 40px 0px rgba(156, 156, 156, 0.3);
  }
}
@keyframes boost {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(10);
  }
}
</style>
