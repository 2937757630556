<template>
  <div id="app">
    <Nav :is-transparent="false" />
    <!-- banner -->
    <a class="Banner">
      <img
        class="banner_img"
        src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/index/news_banner.png"
        alt="惠合科技"
      />
      <div class="banner_text">让商品流通的每个环节变得更简单有趣</div>
    </a>
    <!-- <Banner :isHref='false' :btnStyle="{}" source-url="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/aboutUs/banner.png" alt-text="新闻资讯" /> -->
    <!-- <Banner source-url="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/kqt.mp4" :is-img="false" alt-text="新闻资讯" /> -->
    <!-- 公司愿景 start -->
    <div class="gsyj">
      <div class="title">公司简介</div>
      <div class="flex gsyj-content">
        <div class="gsyj-content_img">
          <video
            src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/video/aboutUs.mp4"
            controls
            autoplay="autoplay"
            loop="loop"
          ></video>
        </div>
        <div class="gsyj-content_text">
          <p>
            杭州惠合信息科技有限公司（以下简称：惠合科技）成立于2016年，是全国领先的店商营销服务平台，总部位于杭州，在上海、广州两地设立营销中心。成立5年时间，惠合科技一直以“传统渠道”为营销阵地，以“让商品流通的每个环节变得更简单有趣”为使命，致力于通过平台化产品及智能化营销方案高效链接品牌商、非连锁门店及消费者，为企业打通传统渠道的营销闭环，实现营销服务数字智能化。
          </p>
          <p>
            依托AI人工智能、大数据分析、一物一码、电子合同、移动支付等前沿技术，惠合科技面向传统营销链条中的品牌方、传统渠道、业务代表及C端消费者，分别上线了VMS、e店佳、快渠通、随心惠四大营销服务平台，实现了传统渠道全链路营销一体化管理。
          </p>
          <p>
            目前，惠合科技已经与百度AI、浙江工业大学取得了深度合作，并服务了可口可乐、农夫山泉、雀巢、玛氏箭牌等百余家国内外知名品牌，吸引了超150万家线下门店入驻。未来，惠合科技将不忘初心，坚持产品和方案并驾齐驱，让百万门店助力快消品牌精准触达14亿C端用户。
          </p>
        </div>
      </div>
    </div>
    <!-- 公司历程 -->
    <div class="gslc bg-theme-light">
      <div class="title">公司历程</div>
      <img
        src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/aboutUs/process.png"
        alt="公司历程"
      />
    </div>
    <!-- 公司荣誉 -->
    <div class="gsry">
      <div class="title">公司荣誉</div>
      <div class="flex gsry-content">
        <div
          :class="['flex item', { m0: index == list.length - 1 }]"
          v-for="(item, index) in list"
          :key="index"
        >
          {{ item }}
        </div>
      </div>
      <div class="banner">
        <swiper
          class="swiper"
          ref="mySwiper"
          :options="swiperOptions"
          v-if="bannerList.length > 0"
        >
          <swiper-slide v-for="(item, index) in bannerList" :key="index">
            <div class="swiper-box">
              <img :src="item" alt />
            </div>
          </swiper-slide>
        </swiper>
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>
    </div>
    <!-- 企业文化 -->
    <div class="qywh">
      <div class="title">企业文化</div>
      <div class="flex qywh-content">
        <div class="qywh-item">
          <div class="qywh-item_img">
            <img
              src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/aboutUs/gssm.png"
              alt
            />
          </div>
          <div class="qywh-item_title">公司使命</div>
          <div class="qywh-item_subtitle">COMPANY MISSION</div>
          <div class="qywh-item_desc">让商品流通的每个环节变得更简单有趣</div>
        </div>
        <div class="qywh-item m0">
          <div class="qywh-item_img">
            <img
              src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/aboutUs/gsyj.png"
              alt
            />
          </div>
          <div class="qywh-item_title">公司愿景</div>
          <div class="qywh-item_subtitle">COMPANY VISION</div>
          <div class="qywh-item_desc">为身边的每个人都提供数字化零售服务</div>
        </div>
      </div>
    </div>
    <Bottom />
  </div>
</template>
<script>
import Nav from "@/components/Nav.vue"
// import Banner from '@/components/Banner.vue'
import Bottom from "@/components/Bottom.vue"
import { swiper, swiperSlide } from "vue-awesome-swiper"
import "swiper/dist/css/swiper.css"
import { title, description, keywords } from "./global"
export default {
  metaInfo: {
    title: title,
    meta: [
      {
        name: "keywords",
        content: keywords,
      },
      {
        name: "description",
        content: description,
      },
    ],
  },
  nama: "AboutUs",
  components: {
    Nav,
    // Banner,
    Bottom,
    swiper,
    swiperSlide,
  },
  data() {
    return {
      list: [
        "国家高新技术企业",
        "3项发明专利",
        "1项实用专利",
        "10项软件著作权",
        "百度大脑权威认证",
      ],
      bannerList: [
        "https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/aboutUs/1.png",
        "https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/aboutUs/2.png",
        "https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/aboutUs/3.png",
        "https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/aboutUs/4.png",
        "https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/aboutUs/5.png",
        "https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/aboutUs/6.png",
        "https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/aboutUs/7.png",
        "https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/aboutUs/8.png",
        "https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/aboutUs/9.png",
        "https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/aboutUs/10.png",
        "https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/aboutUs/11.png",
        "https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/aboutUs/12.png",
      ],
      swiperOptions: {
        autoplay: {
          disableOnInteraction: false,
        },
        speed: 1000,
        loop: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    }
  },
}
</script>
<style lang="less" scoped>
@import url("~@/assets/global.less");
/deep/ .swiper-button-prev {
  width: 40px;
  height: 40px;
  left: -360px;
  background: url("https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/other/arrow_left.png")
    center no-repeat;
  background-size: contain;
}
/deep/ .swiper-button-next {
  width: 40px;
  height: 40px;
  right: -360px;
  background: url("https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/other/arrow_right.png")
    center no-repeat;
  background-size: contain;
}
#app {
  min-width: 1400px;
  padding-top: 88px;
  .title {
    margin-bottom: 52px;
    text-align: center;
    font-size: 46px;
    color: #141414;
  }
  .Banner {
    min-width: 1400px;
    width: 100%;
    position: relative;
    .swiper {
      width: 100%;
    }
    .banner_img {
      min-width: 1400px;
      width: 100%;
      height: 650px;
      display: block;
      object-fit: cover;
      cursor: pointer;
    }
    .banner_text {
      position: absolute;
      top: 270px;
      left: 50%;
      width: 100%;
      text-align: center;
      font-size: 58px;
      color: #fff;
      font-weight: bold;
      transform: translateX(-50%);
      letter-spacing: 2px;
    }
  }
  .gsyj {
    padding: 70px 0 55px;
    .gsyj-content {
      .gsyj-content_img {
        width: 680px;
        height: 380px;
        margin-right: 40px;
        video {
          display: block;
          width: 100%;
        }
      }
      .gsyj-content_text {
        width: 638px;
        line-height: 30px;
        font-size: 18px;
        color: #333;
        p {
          line-height: 26px;
          text-indent: 2em;
          @media only screen and (min-width: 1920px) {
            line-height: 36px;
          }
        }
      }
    }
  }
  .gslc {
    padding: 85px 0 60px;
    text-align: center;
    img {
      max-width: 100%;
    }
  }
  .gsry {
    padding: 47px 0 120px;
    background: url("https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/sdzxBg.png") center
      bottom no-repeat;
    .title {
      margin-bottom: 39px;
    }
    .gsry-content {
      margin-bottom: 107px;
      .item {
        position: relative;
        width: 240px;
        height: 136px;
        margin-right: 30px;
        font-size: 26px;
        font-weight: 400;
        color: #333333;
        background: url("https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/tabBg.png") center
          top no-repeat;
        background-size: 100% 100%;
        border-radius: 4px;
        box-shadow: 0px 2px 8px rgba(156, 156, 156, 0.6);
        align-items: center;
      }
    }
    .banner {
      position: relative;
      width: 580px;
      min-height: 380px;
      margin: 0 auto;
      .swiper-box {
        text-align: center;
        overflow: hidden;
        img {
          max-width: 98%;
        }
      }
    }
  }
  .qywh {
    padding: 40px 0 70px;
    .title {
      margin-bottom: 33px;
    }
    .qywh-content {
      text-align: center;
      color: #141414;
      .qywh-item {
        width: 400px;
        height: 440px;
        margin-right: 150px;
        background: #ffffff;
        border-radius: 4px;
        box-shadow: 0px 2px 8px 0px rgba(72, 72, 72, 0.16);
        box-sizing: border-box;
        overflow: hidden;
        &_img {
          width: 100%;
          height: 0;
          padding-bottom: 59%;
          margin-bottom: 50px;
          overflow: hidden;
          img {
            display: block;
            width: 100%;
          }
        }
        &_title {
          position: relative;
          font-size: 22px;
          font-weight: 500;
          &:before {
            content: "";
            position: absolute;
            top: -9px;
            left: 50%;
            width: 24px;
            height: 4px;
            margin-left: -12px;
            background: #ff7f00;
            border-radius: 2px;
          }
        }
        &_subtitle {
          margin-bottom: 28px;
          font-size: 16px;
          font-weight: 300;
        }
        &_desc {
          font-size: 20px;
          font-weight: 400;
        }
      }
    }
  }
}
</style>
