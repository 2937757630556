/*
 * @Autor: swordman
 * @Date: 2022-06-22 17:34:33
 * @LastEditors: swordman
 * @LastEditTime: 2022-06-23 15:32:54
 * @description: 
 */
/*
 * 项目配置
 *
 * */
// PRE 预发布环境  TEST 测试环境   PRO 生产环境  pre预发布环境
let RUNTIME_ENVIRONMENT = ''

const dommain = location.hostname // 主机域名

if (dommain.search('cos.hzhuihe.cn') !== -1 ||dommain.search('http://k.hh66.cn') !== -1 || (dommain.search('localhost') !== -1)) {
	RUNTIME_ENVIRONMENT = 'TEST' // 测试环境
} else {
	RUNTIME_ENVIRONMENT = 'pro'
}

var PROJECT_CONFIG = {}


if (RUNTIME_ENVIRONMENT === 'pro') {
	/*
   * 生产环境配置
   *
   * */
	PROJECT_CONFIG = {
		VERSION: '2.0.0',
		clientType: 'WEB',
		rootPath: 'web',
		dataServer: 'https://pm.hzhuihe.cn/', // 正式环境
		phone: '18357021750'
	}
}

if (RUNTIME_ENVIRONMENT === 'TEST') {
	/*
   * 测试环境配置
   *
   * */
	PROJECT_CONFIG = {
		VERSION: '1.0.0',
		clientType: 'WEB',
		rootPath: 'web',
		dataServer: 'http://112.17.87.71:8090/', // 测试环境
		phone: '18357021750'
	}
}
PROJECT_CONFIG['env'] = RUNTIME_ENVIRONMENT

export default PROJECT_CONFIG