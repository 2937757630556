<template>
  <div id="app">
    <Nav :is-transparent="false" />
    <!-- banner -->
    <Banner
      source-url="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/ddhx/banners.png"
      title="到店核销"
      :sub-title="subTitle"
      alt-text="到店核销"
    />
    <!-- 适应品牌需求 -->
    <PPXQ :list="list" />
    <!-- 解决方案 -->
    <div class="jjfa">
      <div class="title">解决方案</div>
      <div class="flex jjfa-content">
        <div class="jjfa-content_item">
          <img
            src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/ddhx/4.png"
            class="jjfa-content_item-img"
            alt=""
          />
          <div
            class="jjfa-content_item-text text-left"
            style="margin-left: 12%;"
          >
            通过e店佳平台信息推广
          </div>
          <div
            class="jjfa-content_item-text text-left"
            style="margin-left: 12%;"
          >
            业代到店铺设活动物料
          </div>
        </div>
        <div class="jjfa-content_item">
          <img
            src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/ddhx/5.png"
            class="jjfa-content_item-img"
            alt=""
          />
          <div class="jjfa-content_item-text text-left">
            折扣券/满减券/提货券/<br />代金券
          </div>
        </div>
        <div class="jjfa-content_item">
          <img
            src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/ddhx/6.png"
            class="jjfa-content_item-img"
            alt=""
          />
          <div class="jjfa-content_item-text text-left">收银机扫码核销</div>
          <div class="jjfa-content_item-text text-left">
            消费者扫码领券直接付款核销
          </div>
          <div class="jjfa-content_item-text text-left">
            门店扫描消费者付款码收银核销
          </div>
        </div>
        <div class="jjfa-content_item m0">
          <img
            src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/ddhx/7.png"
            class="jjfa-content_item-img"
            alt=""
          />
          <div class="jjfa-content_item-text text-left">
            e店佳平台直接实现核销结算
          </div>
          <div class="jjfa-content_item-text text-left">
            支持现金、货补多种结算方式
          </div>
        </div>
      </div>
    </div>
    <!-- 方案优势 -->
    <div class="fays">
      <div class="title">方案优势</div>
      <div class="flex fays-item">
        <div class="flex item-left">
          <img src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/ddhx/8.png" alt="" />
        </div>
        <div class="item-right">
          <span class="bold">满足多类型渠道营销需求</span>
          <span>连锁门店/非连锁门店</span>
        </div>
      </div>
      <div class="flex fays-item">
        <div class="flex item-left">
          <img src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/ddhx/9.png" alt="" />
        </div>
        <div class="item-right">
          <span class="bold">多业务流程一码搞定</span>
          <span>“门店签约码、消费者领券码、消费者付款码”三码合一</span>
        </div>
      </div>
      <div class="flex fays-item">
        <div class="flex item-left">
          <img src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/ddhx/10.png" alt="" />
        </div>
        <div class="item-right">
          <span class="bold">支持活动灵活配置</span>
          <span>可按照需求及时调整活动方案</span>
        </div>
      </div>
      <div class="flex fays-item">
        <div class="flex item-left">
          <img src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/ddhx/11.png" alt="" />
        </div>
        <div class="item-right">
          <span class="bold">全业务流程数据监控</span>
          <span>覆盖卡券创建、投放、领取、核销到门店结算的全流程数据监控</span>
        </div>
      </div>
    </div>
    <!-- 客户案例 -->
    <customer-case
      :menu-id="menuId"
      :title="caseTitle"
      :description="caseDesc"
      news-id="48"
      pic-url="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/khal/ddhx.png"
    ></customer-case>
    <!-- 方案保障 -->
    <FABZ />
    <!-- 更多方案 -->
    <more-case></more-case>
    <Bottom />
  </div>
</template>
<script>
import Nav from "@/components/Nav.vue"
import Banner from "@/components/Banner.vue"
import PPXQ from "@/components/PPXQ.vue"
import CustomerCase from "@/components/CustomerCase.vue"
import FABZ from "@/components/FABZ.vue"
import MoreCase from "@/components/MoreCase.vue"
import Bottom from "@/components/Bottom.vue"
import { title, description, keywords } from "./global"
export default {
  metaInfo: {
    title: title,
    meta: [
      {
        name: "keywords",
        content: keywords,
      },
      {
        name: "description",
        content: description,
      },
    ],
  },
  name: "SQYX",
  components: {
    Nav,
    Banner,
    PPXQ,
    CustomerCase,
    FABZ,
    MoreCase,
    Bottom,
  },
  data() {
    return {
      subTitle:
        "为品牌方打通消费者线上领券、引导到店、门店主在线核销的营\n销闭环，实现线上线下流量互通互换。",
      caseTitle: "惠合科技X中外合资大型饮料生产企业",
      caseDesc:
        "该企业2020年开始于惠合科技合作“到店核销”项目，通过匹配不同产品的用户画像，结合全年营销节点，在不同类型渠道开展数字营销活动，实现C端消费者精准触达，提升品牌销量。",
      menuId: "5",
      list: [
        {
          icon: "https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/ddhx/1.png",
          desc: "渠道精准营销\n加速产品推广",
        },
        {
          icon: "https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/ddhx/2.png",
          desc: "沉淀私域流量\n打造营销闭环",
        },
        {
          icon: "https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/ddhx/3.png",
          desc: "增加品牌曝光\n提升整体销量",
        },
      ],
    }
  },
}
</script>
<style lang="less" scoped>
@import url("~@/assets/global.less");
#app {
  padding-top: 88px;
  .title {
    text-align: center;
    font-size: 46px;
    color: #141414;
  }
  .jjfa {
    padding: 50px 0 40px;
    background: #f7f9fa;
    .title {
      margin-bottom: 70px;
    }
    .jjfa-content {
      &_item {
        text-align: center;
        margin-right: 30px;
        &-img {
          display: block;
          width: 350px;
          height: 400px;
          margin-bottom: 24px;
          background: #ffffff;
          img {
            display: block;
            width: 100%;
          }
        }
        &-text {
          position: relative;
          width: 350px;
          padding-left: 20px;
          font-size: 22px;
          font-weight: 400;
          color: #333333;
          box-sizing: border-box;
          &.text-left {
            text-align: left;
          }
          &:before {
            content: "";
            position: absolute;
            width: 8px;
            height: 8px;
            top: 14px;
            left: 0;
            background: #333;
            border-radius: 50%;
          }
        }
      }
    }
  }
  .fays {
    padding: 50px 0;
    .title {
      margin-bottom: 50px;
    }
    .fays-item {
      margin-bottom: 26px;
      .item-left {
        width: 80px;
        height: 80px;
        background: #ffe8c7;
        align-items: center;
        img {
          display: block;
          max-width: 100%;
        }
      }
      .item-right {
        position: relative;
        width: 997px;
        height: 80px;
        padding-left: 30px;
        line-height: 80px;
        font-size: 22px;
        color: #333;
        background: #fffbf7;
        letter-spacing: 1px;
        box-sizing: border-box;
        &:before {
          content: "";
          position: absolute;
          width: 8px;
          height: 8px;
          margin-top: -4px;
          top: 50%;
          left: 15px;
          background: #333;
          border-radius: 50%;
        }
        .bold {
          margin-right: 20px;
          font-size: 26px;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
