<template>
  <div id="app">
    <Nav :is-transparent="false" />
    <!-- banner -->
    <Banner
      source-url="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/clzj/banner.png"
      title="陈列自检"
      :sub-title="subTitle"
      alt-text="陈列自检"
    />
    <!-- 适应品牌需求 -->
    <PPXQ :list="list" />
    <!-- 解决方案 -->
    <div class="jjfa">
      <div class="title">解决方案</div>
      <div class="flex jjfa-content">
        <div class="jjfa-content_item">
          <img
            src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/clzj/5.png"
            class="jjfa-content_item-img"
            alt=""
          />
          <span class="jjfa-content_item-text">门店领取陈列任务</span>
        </div>
        <div class="jjfa-content_item m0">
          <img
            src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/clzj/6.png"
            class="jjfa-content_item-img"
            alt=""
          />
          <span class="jjfa-content_item-text">门店自主陈列</span>
        </div>
      </div>
      <div class="flex jjfa-content">
        <div class="jjfa-content_item">
          <img
            src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/clzj/7.png"
            class="jjfa-content_item-img"
            alt=""
          />
          <span class="jjfa-content_item-text">手机拍摄在线上传</span>
        </div>
        <div class="jjfa-content_item">
          <img
            src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/clzj/8.png"
            class="jjfa-content_item-img"
            alt=""
          />
          <span class="jjfa-content_item-text"
            >AI智能图像识别（后台分析陈列数据）</span
          >
        </div>
        <div class="jjfa-content_item m0">
          <img
            src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/clzj/9.png"
            class="jjfa-content_item-img"
            alt=""
          />
          <span class="jjfa-content_item-text"
            >发放营销奖励（现金红包/货补）</span
          >
        </div>
      </div>
    </div>
    <div class="fays">
      <div class="title">方案优势</div>
      <img
        src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/clzj-ys.png"
        class="fays-img"
        alt="陈列自检"
      />
    </div>
    <!-- 客户案例 -->
    <customer-case
      :menu-id="menuId"
      :title="caseTitle"
      :description="caseDesc"
      news-id="44"
      pic-url="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/khal/clzj.png"
    ></customer-case>
    <!-- 方案保障 -->
    <FABZ />
    <!-- 更多方案 -->
    <more-case :menu-id="menuId"></more-case>
    <Bottom />
  </div>
</template>
<script>
import Nav from "@/components/Nav.vue"
import Banner from "@/components/Banner.vue"
import PPXQ from "@/components/PPXQ.vue"
import CustomerCase from "@/components/CustomerCase.vue"
import FABZ from "@/components/FABZ.vue"
import MoreCase from "@/components/MoreCase.vue"
import Bottom from "@/components/Bottom.vue"
import { title, description, keywords } from "./global"
export default {
  metaInfo: {
    title: title,
    meta: [
      {
        name: "keywords",
        content: keywords,
      },
      {
        name: "description",
        content: description,
      },
    ],
  },
  name: "CLZJ",
  components: {
    Nav,
    Banner,
    PPXQ,
    CustomerCase,
    FABZ,
    MoreCase,
    Bottom,
  },
  data() {
    return {
      subTitle:
        "依托“e店佳”平台，打破传统业代按期执行、人工审核的陈列检核\n方式，驱动门店主主动陈列上传、平台AI审核，及时响应品牌方\n陈列检核需求。",
      caseTitle: "惠合科技X世界500强水饮品牌",
      caseDesc:
        "该品牌每天在华饮用量达1.5亿杯，为中国消费者提供超过15个品牌50多种饮料产品。通过与惠合科技合作“陈列自检”项目，该品牌在广东区域连续三年年销售额复合增长率达20%，门店陈列保持率提升400%。",
      menuId: "3",
      detail: "",
      list: [
        {
          icon: "https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/clzj/1.png",
          desc: "抢占陈列黄金位置\n拉动C端动销",
        },
        {
          icon: "https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/clzj/2.png",
          desc: "缩短管理半径\n提高费用实投率",
        },
        {
          icon: "https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/clzj/3.png",
          desc: "提高图像审核效率\n释放人力成本",
        },
        {
          icon: "https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/clzj/4.png",
          desc: "实时数据监控\n营销快速反应",
        },
      ],
    }
  },
}
</script>
<style lang="less" scoped>
@import url("~@/assets/global.less");
#app {
  padding-top: 88px;
  .title {
    text-align: center;
    font-size: 46px;
    color: #121212;
  }
  .jjfa {
    padding: 50px 0 15px;
    background: #f7f9fa;
    .title {
      margin-bottom: 60px;
    }
    .jjfa-content {
      margin-bottom: 50px;
      &_item {
        text-align: center;
        margin-right: 50px;
        &-img {
          display: block;
          width: 410px;
          height: 260px;
          margin-bottom: 20px;
          background: #ffffff;
          img {
            display: block;
            width: 100%;
          }
        }
        &-text {
          position: relative;
          padding-left: 20px;
          font-size: 22px;
          font-weight: 400;
          color: #333333;
          box-sizing: border-box;
          // letter-spacing: 1px;
          &:before {
            content: "";
            position: absolute;
            width: 8px;
            height: 8px;
            top: 14px;
            left: 0;
            background: #333;
            border-radius: 50%;
          }
        }
      }
    }
  }
  .fays {
    padding-top: 55px;
    .fays-img {
      display: block;
      width: 1242px;
      margin: 0 auto;
    }
  }
}
</style>
