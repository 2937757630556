<template>
  <div id="app">
    <Nav :isTranstparent="true"></Nav>
    <div class="Banner">
      <swiper class="swiper" ref="mySwiper" :options="swiperOptions">
        <swiper-slide class="swiper-no-swiping">
          <div class="banner_img">
            <div class="swiper_text">
              <div class="swiper_text-title">
                全国领先
                <br />店商营销服务平台
              </div>
              <div class="swiper_text-subtitle">让营销服务数字智能化</div>
              <router-link to="/contact?from=首页&moudle=轮播图" class="hrefBtn"
                >立即咨询</router-link
              >
            </div>
            <div class="gifBox">
              <div class="jx">
                <div class="tiao"></div>
              </div>
              <div class="right">
                <div class="tag">SKU名称</div>
                <div class="tag">排面</div>
                <div class="tag">层数</div>
                <div class="tag">个数</div>
                <div class="tag">标签</div>
              </div>
            </div>
            <img
              class="banner_img"
              usemap="#hrefBtn"
              src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/index/index_banner.png"
              alt="惠合科技"
            />
          </div>
        </swiper-slide>
        <swiper-slide class="swiper-no-swiping">
          <div class="banner_img">
            <div class="swiper_text">
              <div class="swiper_text-title">一物一码</div>
              <div class="swiper_text-subtitle ywym">
                提供从营销互动到私域沉淀的
                <br />全链路服务
              </div>
              <router-link to="/kxsm" class="hrefBtn">了解详情</router-link>
            </div>
            <img
              class="round"
              src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/index/round.png"
              alt
            />
            <img
              class="banner_img"
              usemap="#hrefBtn"
              src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/index/index_banner2.png"
              alt="惠合科技"
            />
          </div>
        </swiper-slide>
        <swiper-slide class="swiper-no-swiping">
          <div class="banner_img">
            <div class="swiper_text">
              <div class="swiper_text-title">AI陈列识别</div>
              <div class="swiper_text-subtitle ai">
                既要检核，更要陈列，让店主成为您的理货员
              </div>
              <router-link to="/clzj" class="hrefBtn">了解详情</router-link>
            </div>
            <img
              class="hand"
              src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/index/hand.png"
              alt
            />
            <div class="video_box">
              <video
                class="video"
                autoplay="autoplay"
                muted
                loop="loop"
                src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/index/AI.MP4"
              ></video>
              <img
                class="operate"
                src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/index/operate.png"
                alt
              />
            </div>
            <img
              class="banner_img"
              usemap="#hrefBtn"
              src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/index/index_banner3.png"
              alt="惠合科技"
            />
          </div>
        </swiper-slide>
      </swiper>
      <div class="top_list">
        <div class="top_list_item">
          <p>150万+</p>
          <span>B端门店入驻</span>
        </div>
        <div class="top_list_item">
          <p>1亿+</p>
          <span>C端消费者触达</span>
        </div>
        <div class="top_list_item">
          <p>100+</p>
          <span>知名消费品牌合作</span>
        </div>
        <div class="top_list_item">
          <p>1000+</p>
          <span>营销活动落地</span>
        </div>
      </div>
      <div class="swiper-pagination" slot="pagination"></div>
    </div>
    <!-- “四端在线” -->
    <div class="content">
      <h1 class="content_title">构建“四端在线”营销服务平台</h1>
      <div class="content_subtitle">
        惠合科技以平台为依托，打破了从品牌商、业务代表、门店最后到消费者的单向沟通链路，构建“四端在线”的营销服务平台，实现了信息传达、任务管理、费用核销、结果反馈的在线化和智能化。
      </div>
      <img
        class="content_gif"
        src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/index_GIF.gif?v=1"
        alt
      />
      <ul class="list">
        <li
          :class="{
            list_item: true,
            list_item_select: selectTagIndex === index,
          }"
          v-for="(item, index) in tagList"
          :key="item"
          @mouseenter="handleMouse(index)"
        >
          <span class="list_line"></span>
          <div>{{ item }}</div>
        </li>
      </ul>
      <div class="content_info">
        <div class="content_info_left">
          <p>{{ tagInfo[selectTagIndex].txt }}</p>
          <router-link class="More" :to="tagInfo[selectTagIndex].url"
            >了解详情</router-link
          >
        </div>
        <img
          :class="['content_info_right', 'content_' + selectTagIndex]"
          :src="tagInfo[selectTagIndex].imgUrl"
          alt
        />
      </div>
    </div>
    <!-- 三段式 -->
    <div class="sds">
      <h1 class="content_title">落地“三段式”营销方案</h1>
      <div class="content_subtitle textCenter">
        覆盖商品进店铺货、在店陈列、出店营销的全链路解决方案。
      </div>
      <div class="progress_box">
        <div :class="['progress', 'progress_' + selectTabIndex]"></div>
        <div class="progress_item_box">
          <div
            class="progress_item"
            v-for="(item, index) in tabList"
            :key="item"
          >
            <div
              :class="{
                progress_round: true,
                progress_round_select: index <= selectTabIndex,
              }"
              @mouseenter="handleTabMouse(index)"
            ></div>
            <span>{{ item }}</span>
          </div>
        </div>
      </div>
      <div class="sds_list">
        <div
          class="sds_list_item"
          v-for="item in tabInfo[selectTabIndex]"
          :key="item.imgUrl"
        >
          <div class="sds_img">
            <img :src="item.imgUrl" alt />
            <div class="sds_item_mask"></div>
          </div>
          <div class="sds_bottom">
            <span>{{ item.name }}</span>
            <a :href="item.url">查看详情</a>
          </div>
        </div>
      </div>
    </div>
    <!-- 合作品牌 -->
    <div class="brandList">
      <!-- <div
                class="round"
                v-for="item in 30"
                :key="item"
                :style="{ 'animation-delay': item * 200 + 'ms' }"
            ></div>-->
      <img
        class="img"
        src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/index/brand.png?v=1"
        alt
      />
    </div>
    <!-- 新闻资讯 -->
    <div class="news_box">
      <h1 class="news_title">新闻资讯</h1>
      <div class="new_list">
        <a class="new_list_left" :href="'/newsDetail?id=' + firstNews.id">
          <img class="new_list_left_img" :src="firstNews.picUrl" alt />
          <div class="new_list_left_content">
            <div class="new_left_title txtOver">{{ firstNews.title }}</div>
            <div>{{ firstNews.showReleaseTime | formatTime }}</div>
          </div>
        </a>
        <div class="new_list_right">
          <a
            :href="'/newsDetail?id=' + item.id"
            v-for="item in otherNews"
            class="new_list_right_item"
            :key="item.id"
          >
            <img class="new_list_right_img" :src="item.picUrl" alt />
            <div class="new_list_right_txt">
              <span class="txtOver2">{{ item.title }}</span>
              <span>{{ item.showReleaseTime | formatTime }}</span>
            </div>
          </a>
        </div>
      </div>
      <a class="new_more" href="./news">查看更多</a>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import Nav from "@/components/Nav.vue"
import Bottom from "@/components/Bottom.vue"
import { swiper, swiperSlide } from "vue-awesome-swiper"
// import 'swiper/css/swiper.css'
import "swiper/dist/css/swiper.css"
import { doPost } from "@/utils/request"
import { formatTime } from "@/utils/utils"
import { title, description, keywords } from "./global"
// import lottie from 'lottie-web';
export default {
  metaInfo: {
    title: title,
    meta: [
      {
        name: "keywords",
        content: keywords,
      },
      {
        name: "description",
        content: description,
      },
    ],
  },
  name: "App",
  components: {
    Nav,
    Bottom,
    swiper,
    swiperSlide,
  },
  filters: {
    formatTime(t) {
      return formatTime(t, "Y-M-D")
    },
  },
  data() {
    return {
      bannerList: [
        {
          url: "https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/index/index_banner.jpg",
          path: "/contact",
        },
        {
          url: "https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/index/index_banner.jpg",
          path: "/kgyl",
        },
      ],
      swiperOptions: {
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        speed: 1500,
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
        // loop: true
        // Some Swiper option/callback...
      },
      selectTagIndex: 0,
      tagList: ["VMS 品牌营销管理平台", "e店佳", "快渠通", "随心惠"],
      tagInfo: [
        {
          txt:
            "VMS是面向品牌方的营销管理平台，覆盖B/C端营销项目，提供从活动配置、数据追踪、资金监管到风险控制的全生命周期管理服务。",
          imgUrl: "https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/index/index_vms.png",
          url: "./vms",
        },
        {
          txt:
            "e店佳是面向终端门店的营销活动平台，可以为门店主提供信息获取、任务执行到奖励领取的全流程服务，与品牌商实现 “两端共鸣”。目前，该平台已经入驻150W+传统门店。",
          imgUrl: "https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/index/index_edj.png",
          url: "./edj",
        },
        {
          txt:
            "快渠通是面向品牌销售体系的营销管理平台，可以为各层级管理者提供覆盖门店、人员、活动到费用的在线管理工具，打造数字化营销管理体系。",
          imgUrl: "https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/index/index_kqt.png",
          url: "./kqt",
        },
        {
          txt:
            "随心惠是面向消费者的营销互动平台，借助优惠券形式，为品牌商承接线上流量，实现多类型C端营销活动的线下落地。 目前，该平台已经触达超1亿C端用户。",
          imgUrl: "https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/index/index_sxh.png",
          url: "./sxh",
        },
      ],
      tabList: ["进店营销", "在店营销", "出店营销"],
      tabInfo: [
        [
          {
            name: "开箱扫码",
            imgUrl:
              "https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/index/index_sds_0_1.png",
            url: "/kxsm",
          },
          {
            name: "店主社区",
            imgUrl:
              "https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/index/index_sds_0_2.png",
            url: "/sqyx",
          },
        ],
        [
          {
            name: "陈列自检",
            imgUrl:
              "https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/index/index_sds_1_1.png",
            url: "/clzj",
          },
          {
            name: "业代巡拍",
            imgUrl:
              "https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/index/index_sds_1_2.png",
            url: "/ydxp",
          },
        ],
        [
          {
            name: "到店核销",
            imgUrl:
              "https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/index/index_sds_2_1.png",
            url: "/ddhx",
          },
          {
            name: "开盖有礼",
            imgUrl:
              "https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/index/index_sds_2_2.png",
            url: "/kgyl",
          },
          {
            name: "壹元乐享",
            imgUrl:
              "https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/index/index_sds_1_3.png",
            url: "/yylx",
          },
        ],
      ],
      selectTabIndex: 0,
      firstNews: {},
      otherNews: [],
    }
  },
  created() {
    doPost("pms/officialWebsiteArticle/queryFrontPage.json", "").then((res) => {
      this.firstNews = res.shift()
      this.otherNews = res
    })
  },
  // mounted () {
  //      this.lottie = lottie.loadAnimation({
  //         container: document.querySelector('.banner_gif'),
  //         renderer: 'svg',
  //         loop: true,
  //         path: 'https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/index/data.json'
  //     })
  // },
  methods: {
    handleMouse(index) {
      this.selectTagIndex = index
    },
    handleTabMouse(index) {
      this.selectTabIndex = index
    },
  },
}
</script>

<style lang="less" scoped>
@import url("~@/assets/global.less");
/deep/.swiper-pagination {
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}
/deep/.swiper-pagination-bullet {
  width: 60px;
  height: 6px;
  margin: 0 10px;
  background: #e5e5e5;
  opacity: 0.5;
  border-radius: 0;
}
/deep/.swiper-pagination-bullet-active {
  opacity: 1;
}
.Banner {
  min-width: 1400px;
  width: 100%;
  height: 100vh;
  position: relative;
  .swiper {
    width: 100%;
  }
  .banner_img {
    min-width: 1400px;
    width: 100%;
    height: 100vh;
    display: block;
    object-fit: cover;
    // cursor: pointer;
    position: relative;
    .round {
      position: absolute;
      width: 540px;
      bottom: 230px;
      right: 170px;
      z-index: 10;
    }
    .hand {
      position: absolute;
      width: 555px;
      bottom: 0;
      right: 0;
      z-index: 10;
    }
    .video_box {
      position: absolute;
      width: 210px;
      height: 445px;
      bottom: 276px;
      right: 300px;
      border-radius: 20px;
      transform: rotate(1deg);
      overflow: hidden;
      z-index: 20;
      .video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .operate {
        position: absolute;
        bottom: 20px;
        left: 50%;
        width: 75%;
        transform: translateX(-50%);
        z-index: 10;
      }
    }
  }
  .swiper_text {
    position: absolute;
    left: 154px;
    bottom: 340px;
    color: #fff;
    z-index: 10;
    &-title {
      margin-bottom: 43px;
      line-height: 90px;
      font-size: 72px;
      font-weight: 600;
      letter-spacing: 4px;
    }
    &-subtitle {
      margin-bottom: 30px;
      line-height: 50px;
      font-size: 40px;
      font-weight: 300;
      letter-spacing: 1px;
      &.ywym {
        margin-bottom: 72px;
      }
      &.ai {
        margin-bottom: 118px;
      }
    }
    .hrefBtn {
      width: 206px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-size: 28px;
      font-weight: 400;
      color: #fefefe;
      background: #ff7f00;
      border-radius: 8px;
      letter-spacing: 1px;
    }
  }
  .banner_gif {
    width: 425px;
    height: 616px;
    position: absolute;
    right: 305px;
    top: 191px;
    z-index: 10;
    background: transparent;
  }

  .gifBox {
    perspective: 800px;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    // background: yellowgreen;
    /* height: 600px; */
    padding-top: 30px;
    padding-bottom: 30px;
    width: 400px;
    position: absolute;
    right: 305px;
    top: 191px;
    z-index: 10;
    transition: all 0;
  }

  .jx {
    transition: all 0;
    transform: rotate3d(0, 1, 0, -30deg);
    background-color: rgba(255, 255, 255, 0.3);
    display: flex;
    align-items: flex-start;
    // position: relative;
    width: 250px;
    height: 400px;
    background-image: linear-gradient(
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0)
    );
    animation: height 0.5s forwards;
  }

  .tiao {
    width: 100%;
    height: 2px;
    background: #fff;
    animation: scan 1s forwards;
    transition: all 0;
  }
  @keyframes height {
    0% {
      height: 0;
    }
    100% {
      height: 20.83333vw;
    }
  }
  @keyframes scan {
    0% {
      transform: translateY(0);
    }
    // 90% {
    //     background: #fff;
    // }
    100% {
      transform: translateY(20.83333vw);
      background: transparent;
    }
  }
  .tag {
    transform-origin: left;
    transition: all 0;
    /* transform: rotate3d(0, 1, 0, -30deg); */
    transform: rotate3d(0, 1, 0, -85deg);
    animation: tag 1s forwards 1s;
    width: 138px;
    height: 50px;
    margin-bottom: 30px;
    text-align: center;
    line-height: 50px;
    color: #fff;
    opacity: 0.7;
    font-size: 24px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background-image: linear-gradient(
      -135deg,
      rgba(255, 255, 255, 0.4),
      transparent,
      rgba(255, 255, 255, 0.4)
    );
  }
  @keyframes tag {
    0% {
      transform: rotate3d(0, 1, 0, -85deg);
      color: transparent;
    }
    100% {
      transform: rotate3d(0, 1, 0, -15deg);
      color: #fff;
    }
  }
  .right {
    perspective: 800px;
    transition: all 0;
  }
  .top_list {
    position: absolute;
    left: 8vw;
    bottom: 50px;
    z-index: 10;
    .top_list_item {
      float: left;
      width: 18vw;
      height: 13vw;
      margin-right: 4vw;
      background: rgba(116, 116, 116, 0.3);
      color: #fff;
      font-size: 1vw;
      text-align: center;
      flex-direction: column;
      justify-content: center;
      display: flex;
      border-radius: 5px;
      transition: all 0.3s;
      p {
        font-weight: 600;
        font-size: 2vw;
        color: #fff;
        margin-bottom: 5px;
      }
      &:hover {
        background-image: radial-gradient(#ff9224, #ff7f00);
      }
    }
  }
}
.brandList {
  height: 605px;
  background: url("https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/index/brand_bg.jpg")
    center no-repeat;
  background-size: auto 100%;
  position: relative;
  overflow: hidden;
  .round {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -100px;
    margin-top: -100px;
    border: 1px solid #fff2e6;
    animation: boost 6s 400ms linear infinite;
  }
  .img {
    width: 1587px;
    // height: 465px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 10;
  }
}
.content {
  background: url("https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/index_content_bg.png")
    center top repeat-y;
  background-size: 100% auto;
  background-color: #fefbf6;
  overflow: hidden;
  padding-bottom: 42px;
  .content_gif {
    width: 660px;
    // height: 390px;
    margin: 0 auto;
    display: block;
  }
  .list {
    width: 62.6vw;
    margin: 0 auto;
    overflow: hidden;
    // margin-top: 35px;
    // margin-bottom: 55px;
    padding: 10px;
    .list_item {
      width: 14.58vw;
      height: 8.33vw;
      float: left;
      margin-right: 1.40625vw;
      border-radius: 4px;
      box-shadow: 0 0 8px rgba(156, 156, 156, 0.6);
      text-align: center;
      box-sizing: border-box;
      padding: 0 2.7vw;
      font-size: 1.25vw;
      color: #333333;
      position: relative;
      background: url("https://hbrand.oss-cn-hangzhou.aliyuncs.com/demoVideo/select_box.png")
        center no-repeat;
      background-size: 100% auto;
      background-color: #fff;
      &:last-child {
        margin-right: 0;
      }
      .list_line {
        display: inline-block;
        padding: 2px 15px;
        border-radius: 2px;
        background: @primary-color;
        margin-top: 2.08vw;
        margin-bottom: 0.5vw;
        transition: all 0.3s;
      }
    }
    .list_item_select {
      background-image: radial-gradient(#ff9224, #ff7f00);
      color: #fff;
      &:after {
        content: "";
        width: 0;
        height: 0;
        border: 12px solid @primary-color;
        border-bottom-color: transparent;
        border-left-color: transparent;
        border-right-color: transparent;
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);
      }
      .list_line {
        padding-left: 35px;
        padding-right: 35px;
        background: #fff;
      }
    }
  }
  .content_info {
    // width: 62.6vw;
    padding-left: 360px;
    overflow: hidden;
    margin: 0 auto;
    .content_info_left {
      padding-top: 6%;
      float: left;
      width: 610px;
      p {
        font-size: 1.146vw;
        color: #333333;
        margin-bottom: 50px;
      }
    }
    .content_info_right {
      // max-width: 48%;
      float: left;
    }
    .content_0 {
      width: 780px;
    }
    .content_1 {
      width: 682px;
    }
    .content_2 {
      width: 615px;
    }
    .content_3 {
      width: 614px;
    }
  }
}

.content_title {
  text-align: center;
  font-size: 2.4vw;
  padding-top: 70px;
  color: #141414;
}
.content_subtitle {
  width: 62.5vw;
  font-size: 22px;
  margin: 40px auto;
  font-weight: 400;
}
.More {
  width: 9.375vw;
  height: 2.5vw;
  opacity: 1;
  background: #ff7f00;
  border-radius: 8px;
  line-height: 2.5vw;
  text-align: center;
  color: #fff;
  font-size: 1.104vw;
  cursor: pointer;
}
.textCenter {
  text-align: center;
}

.sds {
  min-height: 400px;
}
.progress_box {
  width: 72.91vw;
  height: 10px;
  margin: 0 auto;
  background: #f0f0f0;
  // background: linear-gradient(90deg, #F0EFEE, 98px,  @primary-color);
  border-radius: 5px;
  position: relative;
  margin: calc((14.58vw - 10px) / 2) auto;
}
.progress {
  width: 23%;
  height: 100%;
  border-radius: 5px;
  background: linear-gradient(90deg, #f0efee, 98px, @primary-color);
  transition: all 0.3s;
}

.progress_0 {
  width: 22.6%;
}
.progress_1 {
  width: 51.6%;
}
.progress_2 {
  width: 100%;
}

.progress_item_box {
  width: 63.125vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  height: 14.58vw;
  align-items: center;
  padding-top: 36px;
  .progress_item {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    color: #333;
    .progress_round {
      width: 46px;
      height: 46px;
      border-radius: 50%;
      box-sizing: border-box;
      border: 3px solid #747474;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 5px;
      transition: all 0.3s;
      &::before {
        content: "";
        width: 26px;
        height: 26px;
        box-sizing: border-box;
        border: 6px solid #747474;
        border-radius: 50%;
      }
    }
    .progress_round_select {
      border-color: @primary-color;
      &::before {
        border-color: @primary-color;
        background: @primary-color;
      }
    }
  }
}

.sds_list {
  padding: 30px 0;
  display: flex;
  justify-content: center;
  .sds_list_item {
    margin-right: 125px;
    border-radius: 10px;
    box-sizing: border-box;
    width: 20.1vw;
    height: 24.48vw;
    overflow: hidden;
    .sds_img {
      width: 100%;
      height: 18.96vw;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
      }
    }
    .sds_item_mask {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.3);
      opacity: 0;
      transition: all 0.3s;
    }
    .sds_bottom {
      width: 100%;
      height: 5.52vw;
      box-sizing: border-box;
      border: 1px solid @primary-color;
      border-top: 0;
      border-radius: 0 0 10px 10px;
      transition: all 0.3s;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 30px;
      color: #333;
      a {
        font-size: 20px;
        color: @primary-color;
        text-decoration: underline;
        margin-top: 0.5vw;
      }
    }
    &:hover {
      .sds_item_mask {
        opacity: 1;
      }
      .sds_bottom {
        background-color: @primary-color;
        color: #fff;
        a {
          color: #fff;
        }
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

.news_box {
  .news_title {
    line-height: 120px;
    font-size: 2.4vw;
    color: #141414;
    text-align: center;
  }
  .new_list {
    width: 1196px;
    margin: 0 auto;
    overflow: hidden;
    font-size: 20px;
    color: #333;
    .new_list_left {
      float: left;
      width: 600px;
      border: 1px solid #e4e4e4;
      border-radius: 4px;
      box-sizing: border-box;
      .new_list_left_img {
        width: 100%;
        height: 302px;
        display: block;
        object-fit: cover;
        // margin-bottom: 20px;
        border-radius: 4px;
      }
      .new_list_left_content {
        height: 90px;
        font-size: 20px;
        padding: 0 10px;
        overflow: hidden;
      }
      .new_left_title {
        margin: 14px 0 6px;
      }
      .txtOver {
        .textOverFlow(1);
      }
    }
    .new_list_right {
      float: right;
      min-width: 186px;
      .new_list_right_img {
        width: 286px;
        height: 114px;
        object-fit: cover;
      }
      .new_list_right_item {
        display: flex;
        margin-bottom: 22px;
        border: 1px solid #e4e4e4;
        border-radius: 4px;
        box-sizing: border-box;
      }
      .new_list_right_txt {
        width: 272px;
        margin-left: 20px;
        font-size: 20px;
        color: #333;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 10px 0;
        height: 114px;
      }
      .txtOver2 {
        .textOverFlow(2);
      }
    }
  }
  .new_more {
    width: 100px;
    height: 32px;
    border-radius: 4px;
    background: @primary-color;
    text-align: center;
    line-height: 32px;
    color: #fff;
    font-size: 16px;
    margin: 20px auto 47px;
    cursor: pointer;
  }
}
@keyframes boost {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(10);
  }
}
</style>
