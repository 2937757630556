<template>
  <div id="app">
    <Nav :is-transparent="false" />
    <Banner
      source-url="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/ydxp/banner.png"
      title="业代巡拍"
      :sub-title="subTitle"
      alt-text="业代巡拍"
    />
    <!-- 适应品牌需求 -->
    <PPXQ :list="list" />
    <!-- 解决方案 -->
    <div class="jjfa">
      <div class="title">解决方案</div>
      <div class="flex jjfa-content">
        <div class="jjfa-content_item">
          <img
            class="jjfa-content_item-img"
            src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/ydxp/4.png"
            alt=""
          />
          <span class="jjfa-content_item-text"
            >业代完成门店签约 <br />（上传门头照和门店信息）</span
          >
        </div>
        <div class="jjfa-content_item">
          <img
            class="jjfa-content_item-img"
            src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/ydxp/5.png"
            alt=""
          />
          <span class="jjfa-content_item-text">选择拍摄门店</span>
        </div>
        <div class="jjfa-content_item m0">
          <img
            class="jjfa-content_item-img"
            src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/ydxp/6.png"
            alt=""
          />
          <span class="jjfa-content_item-text">选择拍摄活动</span>
        </div>
      </div>
      <div class="flex jjfa-content">
        <div class="jjfa-content_item">
          <img
            class="jjfa-content_item-img"
            src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/ydxp/7.png"
            alt=""
          />
          <span class="jjfa-content_item-text">陈列检核拍摄上传</span>
        </div>
        <div class="jjfa-content_item">
          <img
            class="jjfa-content_item-img"
            src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/ydxp/8.png"
            alt=""
          />
          <span class="jjfa-content_item-text"
            >AI智能图像识别（后台分析陈列数据）</span
          >
        </div>
        <div class="jjfa-content_item m0">
          <img
            class="jjfa-content_item-img"
            src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/ydxp/9.png"
            alt=""
          />
          <span class="jjfa-content_item-text"
            >发放活动奖励（现金红包/货补）</span
          >
        </div>
      </div>
    </div>
    <div class="fays">
      <div class="title">方案优势</div>
      <img
        src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/ydxp-ys.png"
        class="fays-img"
        alt="业代巡拍"
      />
    </div>
    <!-- 客户案例 -->
    <customer-case
      :menu-id="menuId"
      :title="caseTitle"
      :description="caseDesc"
      news-id="52"
      pic-url="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/khal/ydxp.png"
    ></customer-case>
    <!-- 方案保障 -->
    <FABZ />
    <!-- 更多方案 -->
    <more-case :menu-id="menuId"></more-case>
    <Bottom />
  </div>
</template>
<script>
import Nav from "@/components/Nav.vue"
import Banner from "@/components/Banner.vue"
import PPXQ from "@/components/PPXQ.vue"
import CustomerCase from "@/components/CustomerCase.vue"
import FABZ from "@/components/FABZ.vue"
import MoreCase from "@/components/MoreCase.vue"
import Bottom from "@/components/Bottom.vue"
import { title, description, keywords } from "./global"
export default {
  metaInfo: {
    title: title,
    meta: [
      {
        name: "keywords",
        content: keywords,
      },
      {
        name: "description",
        content: description,
      },
    ],
  },
  name: "YDXP",
  components: {
    Nav,
    Banner,
    PPXQ,
    CustomerCase,
    FABZ,
    MoreCase,
    Bottom,
  },
  data() {
    return {
      subTitle:
        "依托“快渠通”平台及惠合AI图像识别能力，提高业务代表门店\n陈列、奖励兑付的效率，将更多的精力投入到门店管理和服务中。",
      caseTitle: "惠合科技X全球最大食品生产厂商之一",
      caseDesc:
        "该品牌2020年开始与惠合科技按照门店类型展开“业代巡拍”项目合作。该项目单项活动覆盖率超50000家门店，覆盖率达98%，将费用发放周期从30天缩短为1天，费用实投率达100%。",
      menuId: "4",
      list: [
        {
          icon: "https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/ydxp/1.png",
          desc: "释放业代压力\n提高业务能力",
        },
        {
          icon: "https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/ydxp/2.png",
          desc: "缩短营销链路\n提升费用发放效率",
        },
        {
          icon: "https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/ydxp/3.png",
          desc: "实时数据监控\n科学渠道管理",
        },
      ],
    }
  },
}
</script>
<style lang="less" scoped>
@import url("~@/assets/global.less");
#app {
  padding-top: 88px;
  .title {
    text-align: center;
    font-size: 46px;
    color: #121212;
  }
  .jjfa {
    padding: 50px 0 15px;
    background: #f7f9fa;
    .title {
      margin-bottom: 50px;
    }
    .jjfa-content {
      margin-bottom: 50px;
      &_item {
        text-align: center;
        margin-right: 50px;
        &-img {
          display: block;
          width: 410px;
          height: 260px;
          margin-bottom: 20px;
          background: #ffffff;
          img {
            display: block;
            width: 100%;
          }
        }
        &-text {
          position: relative;
          padding-left: 20px;
          font-size: 22px;
          font-weight: 400;
          color: #333333;
          white-space: pre-line;
          box-sizing: border-box;
          &:before {
            content: "";
            position: absolute;
            width: 8px;
            height: 8px;
            top: 14px;
            left: 0;
            background: #333;
            border-radius: 50%;
          }
        }
      }
    }
  }
  .fays {
    padding-top: 40px;
    .fays-img {
      display: block;
      width: 1242px;
      margin: 0 auto;
    }
  }
}
</style>
