<template>
  <div id="app">
    <Nav :is-transparent="false"></Nav>
    <!-- <swiper class="swiper" ref="mySwiper" :options="swiperOptions">
        <swiper-slide v-for="(item, index) in bannerList" :key="index">
          <img class="banner_img" :src="item" alt="惠合科技" />
        </swiper-slide>
    </swiper>-->
    <Banner
      source-url="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/video/edj.mp4"
      :isImg="false"
      title="e店佳"
      :sub-title="subTitle"
      alt-text="e店佳"
    />
    <div class="flex title-list bg-theme-light">
      <div
        :class="[
          'flex title-item',
          { active: currentIndex === index },
          { m0: index == tabList.length - 1 },
        ]"
        v-for="(item, index) in tabList"
        :key="index"
        @click="handleChange(index)"
      >
        {{ item }}
      </div>
    </div>
    <div class="flex bg-theme-light wrapper wrapper1" id="id1" ref="wrapper1">
      <div class="flex desc-box">
        <div class="desc-title">品牌信息获取</div>
        <div class="desc-text">
          向门店主展现品牌方相关营销资讯，让信息传达从线下走向线上，实现去
          <br />中心化。
        </div>
        <div class="flex desc-icon">
          <div class="desc-icon_box">
            <img
              src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/edj/1-1.png"
              alt="e店佳"
              class="icon-box_img"
            />
            <p>品牌活动资讯</p>
          </div>
          <div class="desc-icon_box m0">
            <img
              src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/edj/1-2.png"
              alt="e店佳"
              class="icon-box_img"
            />
            <p>品牌政策资讯</p>
          </div>
        </div>
      </div>
      <img
        class="pic"
        src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/edj/1.png"
        alt="e店佳"
      />
    </div>
    <div class="flex wrapper wrapper2" id="id2" ref="wrapper2">
      <img
        class="pic"
        src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/edj/2.png"
        alt="e店佳"
      />
      <div class="flex desc-box">
        <div class="desc-title">营销活动执行</div>
        <div class="desc-text">
          门店主在线参与活动，实现从被动安排到主动执行的跨越。
        </div>
        <div class="flex desc-icon">
          <div class="desc-icon_box">
            <img
              src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/edj/2-1.png"
              alt="e店佳"
              class="icon-box_img"
            />
            <p>在线签约</p>
            <p>成为活动网点</p>
          </div>
          <div class="desc-icon_box m0">
            <img
              src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/edj/2-2.png"
              alt="e店佳"
              class="icon-box_img"
            />
            <p>图像上传</p>
            <p>AI自动审核</p>
          </div>
        </div>
      </div>
    </div>
    <div class="flex bg-theme-light wrapper wrapper3" id="id3" ref="wrapper3">
      <div class="flex desc-box">
        <div class="desc-title">奖励在线兑付</div>
        <div class="desc-text">
          为门店主提供活动奖励实时在线兑付服务，提高品牌费用实投率和发放
          <br />效率。
        </div>
        <div class="flex desc-icon">
          <div class="desc-icon_box">
            <img
              src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/edj/3-1.png"
              alt="e店佳"
              class="icon-box_img"
            />
            <p>货补奖励</p>
          </div>
          <div class="desc-icon_box m0">
            <img
              src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/edj/3-2.png"
              alt="e店佳"
              class="icon-box_img"
            />
            <p>现金红包</p>
          </div>
        </div>
      </div>
      <img
        class="pic"
        src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/edj/3.png"
        alt="e店佳"
      />
    </div>
    <div class="flex wrapper wrapper4" id="id4" ref="wrapper4">
      <img
        class="pic"
        src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/edj/4.png"
        alt="e店佳"
      />
      <div class="flex desc-box">
        <div class="desc-title">店主社区</div>
        <div class="desc-text">
          以视频和图文形式，提升门店主在平台的活跃度，从而高效传达品牌信息，提升品牌曝光率。
        </div>
        <div class="flex desc-icon">
          <div class="desc-icon_box">
            <img
              src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/edj/4-1.png"
              alt="e店佳"
              class="icon-box_img"
            />
            <p>行业资讯</p>
          </div>
          <div class="desc-icon_box">
            <img
              src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/edj/4-2.png"
              alt="e店佳"
              class="icon-box_img"
            />
            <p>达人分享</p>
          </div>
          <div class="desc-icon_box">
            <img
              src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/edj/4-3.png"
              alt="e店佳"
              class="icon-box_img"
            />
            <p>好物推荐</p>
          </div>
          <div class="desc-icon_box m0">
            <img
              src="https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/edj/4-4.png"
              alt="e店佳"
              class="icon-box_img"
            />
            <p>零售叭叭</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 四端在线start -->
    <SDZX></SDZX>
    <!-- 平台保障start -->
    <PTBZ></PTBZ>
    <Bottom></Bottom>
  </div>
</template>

<script>
import Nav from "@/components/Nav.vue"
import Banner from "@/components/Banner.vue"
import Bottom from "@/components/Bottom.vue"
import SDZX from "@/components/SDZX.vue"
import PTBZ from "@/components/PTBZ.vue"
import { title, description, keywords } from "./global"
// import { swiper, swiperSlide } from "vue-awesome-swiper";
// import "swiper/dist/css/swiper.css";
export default {
  metaInfo: {
    title: title,
    meta: [
      {
        name: "keywords",
        content: keywords,
      },
      {
        name: "description",
        content: description,
      },
    ],
  },
  name: "App",
  components: {
    Nav,
    Banner,
    Bottom,
    SDZX,
    PTBZ,
    // swiper,
    // swiperSlide,
  },
  data() {
    return {
      subTitle:
        "面向门店端的营销活动平台，为门店主提供从信息获取、任务执行\n到奖励领取的全流程服务，缩小品牌管理半径，提升费用实投率。",
      bannerList: [
        "https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/vmsBanner.png",
        "https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/vmsBanner.png",
      ],
      swiperOptions: {
        autoplay: true,
        speed: 1000,
        loop: true,
        // Some Swiper option/callback...
      },
      tabList: ["品牌信息获取", "营销活动执行", "奖励在线兑付", "店主社区"],
      currentIndex: 0,
    }
  },
  methods: {
    handleChange(index) {
      this.currentIndex = index
      let offsetTop = this.$refs[`wrapper${index + 1}`].offsetTop - 88
      try {
        window.scrollTo({
          top: offsetTop,
          behavior: "smooth",
        })
      } catch (e) {
        window.scrollTo(0, offsetTop)
      }
    },
  },
  created() {},
}
</script>

<style lang="less" scoped>
@import url("~@/assets/global.less");
#app {
  padding-top: 88px;
}
.title-list {
  padding: 4vw 0;
  .title-item {
    position: relative;
    width: 13vw;
    height: 5.5vw;
    margin-right: 3vw;
    padding-bottom: 0.6vw;
    font-size: 1vw;
    font-weight: 400;
    color: #333333;
    background: url("https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/tabBg.png") center top
      no-repeat;
    background-size: 100% 100%;
    background-clip: content-box;
    border-radius: 4px;
    filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.15));
    justify-content: center;
    align-items: center;
    &:before {
      content: "";
      position: absolute;
      top: 20px;
      width: 30px;
      height: 4px;
      background: #333;
      border-radius: 2px;
    }
    &.active {
      color: #fff;
      box-shadow: none;
      background: url("https://hbrand.oss-cn-hangzhou.aliyuncs.com/new_website/tabActiveBg.png")
        top center no-repeat;
      background-size: 100% 100%;
      background-clip: border-box;
      &:before {
        background: #fff;
      }
    }
  }
}
.wrapper {
  align-items: center;
  .desc-box {
    width: 695px;
    flex-direction: column;
    font-size: 22px;
    align-items: center;
    .desc-title {
      margin-bottom: 2vw;
      font-size: 2vw;
      color: #141414;
    }
    .desc-text {
      margin-bottom: 1vw;
    }
    .desc-icon {
      justify-content: space-around;
      .desc-icon_box {
        margin-right: 4vw;
        text-align: center;
        .icon-box_img {
          width: 76px;
          margin-bottom: 1vw;
        }
        p {
          font-size: 1vw;
        }
      }
    }
  }
  .pic {
    width: 641px;
  }
  &.wrapper1 {
    .desc-box {
      white-space: nowrap;
    }
  }
  &.wrapper2 {
    padding: 1vw 0;
  }
  &.wrapper3 {
    padding: 2vw 0;
  }
  &.wrapper4 {
    padding: 25px 0 50px;
    .desc-icon {
      width: 100%;
      justify-content: space-between;
    }
  }
}
</style>
